<template>
  <div>
    <div v-if="loading">
      <LoadingSpinner aria-describedby="Print preview is loading" />
    </div>
    <div v-else id="to-print">
      <div class="text-[#510D76] font-bold text-3xl border-b border-cool-gray">
        Mentor Log
      </div>
      <div class="text-[#510D76] text-dark-purple font-bold text-2xl mb-1.5">
        List of Approved Events for {{ student.firstname }}
        {{ student.lastname }} ({{
          student.profiles[0]?.acad_level.level
        }})
      </div>
      <div class="text-[#510D76] font-bold text-xl">
        Mentor {{ student.mentors > 1 ? "(s)" : "" }}:
      </div>
      <div
        class="text-[#510D76] font-bold text-base mb-8"
        v-for="mentor in student.mentors"
        :key="mentor.mentor_id"
      >
        {{ mentor.prefix }} {{ mentor.firstname }} {{ mentor.lastname }}
        {{ mentor.suffix }}
      </div>
      <div>
        <PlanPrint
          v-if="plan && student && reviewing_mentor"
          id="print_ppdp"
          :plan="plan"
          :student="student"
          :mentor="reviewing_mentor"
        />
      </div>
      <div class="text-[#510D76] font-bold text-3xl border-b border-cool-gray">
        Fieldwork Activity
      </div>
      <div v-for="item in fieldworks" :key="item.title" class="mb-8">
        <div class="text-[#510D76] text-dark-purple font-bold text-2xl mb-1.5">
          {{ item.fieldwork_type_name
          }}{{
            item.fieldwork_type_name === "Experience"
              ? ": " + value_lookup(item, ["Experience Type"])
              : ""
          }}
          on {{ FormatDate(item.fieldwork_date) }}
        </div>
        <div
          v-if="
            value_lookup(item, ['Mentor Present']) === 'Yes' ||
            value_lookup(item, ['Mentor(s) Present']) === 'Yes'
          "
        >
          <div v-if="value_lookup(item, ['Mentor Name(s)'])">
            <strong>Mentor(s) Present:</strong>
            {{ value_lookup(item, ["Mentor Name(s)"]) }}
          </div>
          <div v-else>
            <strong>Mentor(s) Present:</strong>
            {{
              value_lookup(item, ["Title of Attorney/Judge"]) +
              " " +
              value_lookup(item, ["First Name of Attorney/Judge"]) +
              " " +
              value_lookup(item, ["Last Name of Attorney/Judge"])
            }}
          </div>
        </div>
        <div v-if="item.fieldwork_type_name === 'Point of Contact'">
          <div v-if="value_lookup(item, ['Mentor Name(s)'])">
            <strong>Mentor(s) Present:</strong>
            {{ value_lookup(item, ["Mentor Name(s)"]) }}
          </div>
          <div v-else>
            <strong>Other Lawyers / Judges Attending:</strong>
            {{ value_lookup(item, ["Staff Member's Name"]) }}
          </div>
        </div>
        <div>
          <strong>Description of Event:</strong> <br />
          {{
            value_lookup(item, [
              "Description",
              "Description of Debrief",
              "Description of Networking Conversation",
              "Description of Orientation",
              "Description of Point of Contact",
              "Description of Year-End Meeting",
            ])
          }}
        </div>
        <div v-if="item.fieldwork_type_name !== 'Year-End Meeting'">
          <div v-if="value_lookup(item, ['Time Spent'])">
            <strong>Time Spent:</strong>
            {{ value_lookup(item, ["Time Spent"]) }} hrs.
          </div>
          <div v-else-if="value_lookup(item, ['PPDP Meeting Time'])">
            <strong>Time Spent:</strong>
            {{ value_lookup(item, ["PPDP Meeting Time", "PPDP Draft Prep and Meeting Time"]) }} hrs.
          </div>
          <div v-else>
            <strong
              >Total Time:
              {{
                parseFloat(value_lookup(item, ["Preparation"])) +
                parseFloat(value_lookup(item, ["Travel Time", "Travel"])) +
                parseFloat(value_lookup(item, ["Event","PPDP Draft Prep and Meeting Time"]))
              }}
              hrs.</strong
            >
            | Prep Time: {{ value_lookup(item, ["Preparation"]) }} | Travel
            Time: {{ value_lookup(item, ["Travel Time", "Travel"]) }} | Event
            Time:
            {{value_lookup(item, ["Event","PPDP Draft Prep and Meeting Time"]) }}
          </div>
        </div>
        <div
          v-if="
            value_lookup(item, ['Mentor Present']) === 'No' ||
            value_lookup(item, ['Mentor(s) Present']) === 'No'
          "
        >
          <strong>Other Lawyers / Judges Attending:</strong>
          {{
            value_lookup(item, ["Title of Other Attorney/Judge"]) +
            " " +
            value_lookup(item, ["First Name of Other Attorney/Judge"]) +
            " " +
            value_lookup(item, ["Last Name of Other Attorney/Judge"])
          }}
        </div>
        <div v-if="value_lookup(item, ['Location'])">
          <strong>Location:</strong> {{ value_lookup(item, ["Location"]) }}
        </div>
      </div>
      <div class="text-[#510D76] font-bold text-3xl">
        Brief Summary
      </div>
      <div>
        <table class="border border-cool-gray w-3/4">
          <tr class="border border-cool-gray">
            <th class="w-1/2 text-left">Requirement Description</th>
            <th class="w-1/4">Required Number</th>
            <th class="w-1/4">Total</th>
          </tr>
          <tr class="border border-cool-gray">
            <td>Number of distinct experience types</td>
            <td class="text-center">{{ progress[1].progress.total }}</td>
            <td class="text-center">{{ progress[1].progress.complete }}</td>
          </tr>
          <tr class="border border-cool-gray">
            <td>Total hours of in-field work</td>
            <td class="text-center">{{ progress[3].progress.total }}</td>
            <td class="text-center">{{ progress[3].progress.complete }}</td>
          </tr>
          <tr class="border border-cool-gray">
            <td>Initial plan completed</td>
            <td class="text-center">{{ progress[0].progress.total }}</td>
            <td class="text-center">{{ progress[0].progress.complete }}</td>
          </tr>
          <tr class="border border-cool-gray">
            <td>Number of debriefings</td>
            <td class="text-center">{{ progress[2].progress.total }}</td>
            <td class="text-center">{{ progress[2].progress.complete }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, useStore } from "vuex";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import { FormatDate } from "@/composables/date_utils";
import PlanPrint from "@/views/student/plan/PlanPrint";

export default {
  name: "FieldworksPrint",
  components: {
    LoadingSpinner,
    PlanPrint,
  },
  data: function () {
    return {
      loading: true,
      fieldworks: [],
      progress: this.$store.state.fieldwork_form_store.progress_items,
    };
  },
  created() {
    const store = useStore();
    this.fetchPlanResource(
      this.student.profiles[0]?.acad_level
    );
    if (store.state.fieldwork_form_store.entries.length === 0) {
      store.dispatch("getFieldworkEntries").then(() => {
        this.fieldworks = store.state.fieldwork_form_store.entries.filter(
          (f) => f.event_status === "APPROVED" && f.confidential === false
        );
        this.fieldworks.sort((a, b) => a.fieldwork_date > b.fieldwork_date);
        this.loading = false;
      });
    } else {
      this.fieldworks = store.state.fieldwork_form_store.entries.filter(
        (f) => f.event_status === "APPROVED" && f.confidential === false
      );
      this.fieldworks.sort((a, b) => {
        return new Date(a.fieldwork_date) - new Date(b.fieldwork_date);
      });
      this.loading = false;
    }
  },
  mounted() {
    const tw_classes = [];
    const options = {
      name: "_blank",
      specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
      styles: tw_classes,
      timeout: 1000, // default timeout before the print window appears
      autoClose: true, // if false, the window will not close after printing
      windowTitle: window.document.title, // override the window title
    };
    this.$htmlToPaper("to-print", options);
  },
  methods: {
    FormatDate,
    ...mapActions(["getFieldworkEntries", "fetchPlanResource"]),
    getValue: function (val, type) {
      if (type === "NUMBER" && !val) return 0;
      return val;
    },
    value_lookup: function (item, field_names) {
      let return_value = "";
      const lookup = this.lookup_fieldwork_types.find(
        (l) => l.fieldwork_log_type_id === item.fieldwork_type_id
      );
      item.fields.forEach((field) => {
        let value = lookup.fields.find(
          (l) => field.fieldwork_log_field_id === l.field_id
        );
        field_names.forEach((name) => {
          if (value.name === name) {
            return_value = this.getValue(field.value, value.type);
          }
        });
        return_value = this.getValue(return_value, value.type);
      });
      return return_value;
    },
    print_fields: function () {
      this.fieldworks.forEach((item) => {
        console.log(item.fieldwork_type_name);
        const lookup = this.lookup_fieldwork_types.find(
          (l) => l.fieldwork_log_type_id === item.fieldwork_type_id
        );
        item.fields.forEach((field) => {
          let value = lookup.fields.find(
            (l) => field.fieldwork_log_field_id === l.field_id
          );
          console.log(value.name, " -- ", field.value);
        });
        console.log("---");
      });
    },
  },
  computed: {
    ...mapGetters(["lookup_fieldwork_types"]),
    ...mapState({
      student: (state) => state.student,
      plan: (state) => state.plan,
    }),
    reviewing_mentor() {
      return this.student.mentors.find(
        (mentor) => mentor.mentor_id === this.plan.reviewing_mentor_id
      );
    },
  },
};
</script>

<style scoped></style>
